import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/occasion/data';
import futureCelebrating from '../../images/occasion/teacher-appreciation.png';
import futurePhone from '../../images/occasion/iPhone12.png';
import HeaderSection from '../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionTeacherAppreciation: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Celebrate teachers with group gifts and ecards from illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Sincere Teacher Appreciation Gifts'}
          paragraphs={[
            'Teachers often view their work as a calling. They don’t seek the spotlight – they’d rather make a difference in their students’ lives. So at the end of this school year, let your teacher know their impact with a group gift from illume.',
            'Pick out a unique gift from illume’s digital marketplace, contribute with personal messages, photos, and more – and then hit send when they’re ready.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=b113927f-ce61-4694-89fb-882f9be199df&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Teacher Appreciation',
            },
            phone: {
              image: futurePhone,
              label: 'Teacher Appreciation Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of saying thanks'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionTeacherAppreciation;
